import { Action, createReducer, on } from '@ngrx/store';
import { ProfileStoreState } from './profile.state';
import { ProfileActions } from './profile.actions';

export const profileInitialState: ProfileStoreState = {
  authResponce: null,
  authError: null,
  profile: null,
};

const createProfileReducer = createReducer(
  profileInitialState,
  on(ProfileActions.load, state => ({
    ...state,
  })),
  on(ProfileActions.loadSuccess, (state, action) => ({
    ...state,
    profile: action.profile,
  })),
  on(ProfileActions.loadFail, (state) => ({ ...state, profile: null, loading: false, loaded: false })),

  on(ProfileActions.loginProcess, (state) => ({
    ...state,
    authError: null,
  })),

  on(ProfileActions.loginProcessSuccess, (state, action) => ({
    ...state,
    authResponce: action.payload,
    authError: null,
  })),
  on(ProfileActions.loginProcessFail, (state, action) => ({
    ...state,
    authError: action.error.error,
  })),

  on(ProfileActions.login, (state) => ({
    ...state,
    authError: null,
  })),
  on(ProfileActions.loginSuccess, (state) => ({
    ...state,
    authError: null,
  })),

  on(ProfileActions.loginFail, (state, action) => ({
    ...state,
    authError: action.error.error,
  })),

  on(ProfileActions.logout, (state) => ({
    ...state,
    authResponce: null,
    authError: null,
    profile: null,
  })),

  on(ProfileActions.setProfileData, (state, action) => ({
    ...state,
    profile: action.payload,
  })),

  on(ProfileActions.clearAuthError, (state) => ({
    ...state,
    authError: null,
  }))
);

export function profileReducer(state: ProfileStoreState, action: Action): ProfileStoreState {
  return createProfileReducer(state, action);
}
