export interface Profile {
  accountId: number;
  email: string;
  isPassed: boolean;
  phone: string;
  token: string;
  username: string;
  role: 'SUPER_USER' | 'USER';
}

export interface AuthStepResponce {
  code_length: number;
  code_lifetime: number;
  key: string;
  next: AuthSteps;
  mask: string;
}

export enum TypeVerifiedRegister {
  phone = 'phone',
  email = 'email',
}

export enum AuthSteps {
  PHONE = 'PHONE',
  EMAIL = 'EMAIL',
  PHONE_CODE = 'PHONE_CODE',
  EMAIL_CODE = 'EMAIL_CODE',
  LOGIN = 'LOGIN',
}

