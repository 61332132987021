import { createAction, props } from '@ngrx/store';
import { AuthStepResponce, Profile } from './profile.model';

export namespace ProfileActions {
  export const login = createAction(
    '[Profile] login',
    props<{ key: string }>()
  );

  export const loginSuccess = createAction(
    '[Profile] login success',
    props<{ payload: Profile }>()
  );

  export const loginFail = createAction(
    '[Profile] login fail',
    props<{ error: any }>()
  );

  /** login process  */
  export const loginProcess = createAction(
    '[Profile] login process',
    props<{ payload: { value: string, key: string | null, step: string } }>()
  );

  export const loginProcessSuccess = createAction(
    '[Profile] login process success',
    props<{ payload: AuthStepResponce }>()
  );

  export const loginProcessFail = createAction(
    '[Profile] login process fail',
    props<{ error: any }>()
  );

  export const load = createAction('[Profile] load');

  export const loadSuccess = createAction(
    '[Profile] load success',
    props<{ profile: Profile }>()
  );

  export const loadFail = createAction(
    '[Profile] load fail',
    props<{ error: any }>()
  );

  export const setProfileData = createAction(
    '[Profile] set profile data',
    props<{ payload: Profile }>()
  );

  export const clearAuthError = createAction('[Profile] clear auth error');
  export const logout = createAction('[Profile] logout');
}
