import { createSelector } from '@ngrx/store';
import { ProfileStoreState } from './profile.state';


export namespace ProfileSelectors {

  export const selectProfile = (state: { profile: ProfileStoreState }) => state.profile;
  export const selectAuthResponce = createSelector(selectProfile, ({ authResponce }: ProfileStoreState) => authResponce);
  export const selectAuthError = createSelector(selectProfile, ({ authError }: ProfileStoreState) => authError);
  export const selectProfileInformation = createSelector(selectProfile, ({ profile }: ProfileStoreState) => profile);
}
